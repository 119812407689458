import request from '../utils/request'

// 登录
export function selectPreRegistrationInfo(data) {
    return request({
        url: '/company/selectPreRegistrationInfo',
        method: 'get',
        params: data
    })
}
