<!-- 公司列表页面 -->
<template>
    <div class="container">
        <BreadcrumbNav :pageName="$t('memberClassification.company')"></BreadcrumbNav>
        <div class="body_box auto">
            <div class="classify_nav">
                <!--  二级分类  -->
                <div class="classify_nav_two fl">
                    <div class="nav_two_title fl">{{ $t("memberClassification.location") }}</div>
                    <div class="fl">
                        <el-select :placeholder="$t('message.pleaseSelect')" @change="getCompanyList()" clearable
                                   filterable style="width: 124px"
                                   v-model="locationValue">
                            <el-option :key="item.id" :label="item.localtionName" :value="item.localtionName"
                                       v-for="item in locationList">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!--  搜索  -->
                <div class="classify_nav_three fr">
                    <input :placeholder="$t('memberClassification.corporateName')" @keyup.enter="getCompanyList()"
                           type="text" v-model="keyword">
                    <div @click="getCompanyList()" class="fr search_btn cur">
                        <img alt="" height="18" src="../../assets/images/icon_search@2x.png" width="18">
                    </div>
                </div>
                <!--所属行业-->
                <div class="fl classify_nav_three fr" style="width:200px;margin-right: 18px">
                    <input :placeholder="$t('message.searchIndustry')" style="width:170px;" type="text"
                           v-model="industrySearch">
                </div>
                <div class="clearfix"></div>
            </div>
            <!--  列表  -->
            <div class="people_list" style="text-align: center">
                <h1 v-if="companyList.length==0">{{ $t("myWallet.noDataAvailable") }}</h1>

                <div :key="index" @click="$router.push({
            path: '/user/companyDetails',
            query:{
              companyId:item.company_id
            }})" class="people_item fl"
                     v-for="(item,index) in companyList">
                    <p class="photo">
                        <img :onerror="default_cattle_company"
                             :src="item.company_logo"
                             alt=""
                             height="120px" style="cursor: pointer" width="120px"
                        >

                    </p>
                    <h2 class="over">{{ item.company_name }}</h2>
                    <p class="introduce over3">{{ item.location }}</p>
                </div>

                <div class="clearfix"></div>
            </div>
            <!--  分页  -->
            <div class="Pagination_box">
                <el-pagination
                        :current-page="pageNum"
                        :page-size="36"
                        :total="pageCount"
                        @current-change="handleCurrentChange"
                        layout="prev, pager, next,slot"
                        style="text-align: center">
                    <slot>
                        <div class="jump-page">
                            <span>{{ $t("memberClassification.goTo") }}</span>
                            <input @keyup.enter="goJumpPage(jumpPageNum)" type="text" v-model="jumpPageNum">
                            <i @click="goJumpPage(jumpPageNum)" class="el-icon-arrow-right"></i>
                        </div>
                    </slot>
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import Classify from '@/components/member/classify'
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import {positionList, locationList, disclaimer, recommend, followTalent} from '@/api/memberApi'
    import {uploadingResume} from '@/api/uploadApi'
    import {Loading as elLoading} from "element-ui";
    import {selectPreRegistrationInfo} from "@/api/companyApi";

    let loadingInstance = null;
    export default {
        name: "index",
        data() {
            return {
                default_cattle_company: 'this.src="' + require('../../assets/images/gongsi_icon.png') + '"', //加载图片报错时处理方法

                fileName: '',
                actionUrl: uploadingResume,
                resume: '',
                companyList: [],
                pageNum: 1,
                pageCount: 0,
                jumpPageNum: '',
                keyword: '',
                industrySearch: '',
                disclaimerMsg: [],
                linkedinWebsite: '',//领英网址
                position: '',
                positionList: [],
                locationList: [],
                positionValue: '',
                locationValue: '',
                showPosition: true,
            }
        },
        components: {
            Classify,
            BreadcrumbNav,
        },
        methods: {
            gopage(url) {
                this.$router.push(url)
            },
            //获取公司列表
            getCompanyList() {
                let formData = {
                    pageNum: this.pageNum,
                    pageSize: 36,
                    location: this.locationValue,
                    companyName: this.keyword,
                    industrySearch: this.industrySearch,
                    secIndustryId: this.$route.query.id,
                    financingId: this.$route.query.financingId,
                }
                selectPreRegistrationInfo(formData).then((res) => {
                    if (res.code == 200) {
                        this.companyList = res.data.list;
                        this.pageCount = res.data.total;
                    } else {
                        this.companyList = [];
                        this.pageCount = 1;
                        /*this.$message.error(res.msg)*/
                    }
                })
            },

            goJumpPage(num) {
                this.pageNum = num;
                this.getCompanyList();
            },

            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                this.getCompanyList();
            },


            // 地区查询条件列表
            getLocationList() {
                let formData = {
                    token: localStorage.getItem('token'),
                }
                locationList(formData).then((res) => {
                    this.locationList = res.data;
                })
            }
        },
        mounted() {
            this.getCompanyList();
            this.getLocationList();
        }
    }
</script>

<style scoped>


    .container {
        width: 100%;
        min-height: 88vh;
    }

    .body_box {
        width: 1200px;
    }

    .classify_nav {
        padding-bottom: 28px;
        box-sizing: border-box;
        border-bottom: 2px solid #E5E5E5;
        vertical-align: middle;
        margin-top: 28px;
        line-height: 40px;
    }


    .nav_one_btn img {
        margin-top: 14px;
    }

    .classify_nav_two {
        /*margin-left: 66px;*/
    }

    .nav_two_title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-right: 9px;
    }


    .classify_nav_three {
        width: 343px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 40px;
    }

    .classify_nav_three input {
        width: 230px;
        background: #FAFAFA;
        margin-left: 18px;
    }

    .search_btn {
        width: 65px;
        height: 40px;
        background: #000000;
        border-radius: 131px;
        text-align: center;
        line-height: 40px;
    }

    .search_btn img {
        margin-bottom: 4px;
    }

    /*  列表  */

    .people_list {
        width: 100%;
        margin-top: 35px;
    }

    .people_item {
        width: 283px;
        height: 257px;
        background: #FFFFFF;
        border-radius: 10px;
        box-sizing: border-box;
        margin-right: 22px;
        margin-bottom: 50px;
        transition: .3s all;
    }

    .people_item:nth-child(4n) {
        margin-right: 0;
    }

    .people_item:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.09);
    }

    .people_item .photo {
        text-align: center;
        margin-top: 20px;
    }

    .photo img {
        border-radius: 50%;
    }

    .people_item > h2 {
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 20px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 23px;
        color: #000000;
        text-align: center;
        margin-top: 18px;
    }

    .people_item .company_name {
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        text-align: center;
        margin-top: 5px;
    }

    .people_item .introduce {
        height: 49px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #949494;
        padding: 0 46px;
        box-sizing: border-box;
        text-align: center;
        margin-top: 5px;
    }

    .btn_box {
        margin-top: 19px;
        padding: 0 28px;
    }

    .btn_box > div {
        width: 100px;
        height: 40px;
        border: 1px solid #707070;
        border-radius: 25px;
        text-align: center;
        line-height: 38px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .attention_active {
        border: 1px solid #F1F1F1 !important;
    }

    .Pagination_box {
        height: 40px;
        margin-top: 45px;
    }


    .jump-page {
        display: inline-block;
        vertical-align: middle;
        width: 124px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        margin-left: 30px;
        line-height: 40px !important;
        text-align: center;

    }

    .jump-page > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        vertical-align: middle;
        line-height: 23px !important;
    }

    .jump-page > input {
        background: transparent;
        width: 40px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin-top: 2px;
    }

    .jump-page > i {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        font-weight: 700;
        font-size: 19px;
    }
</style>
